import React from 'react'
import Button from '@mui/material/Button'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import { usePaymentOrderData } from '../../lib/global/paymentorderdata_hook'
import PaymentIcon from '@mui/icons-material/Payment'
import { LinearProgress } from '@mui/material'

export interface OrderRepayButtonProps {
  orderId: number
  mailAddress: string
}

export default function OrderRepayButton(props: OrderRepayButtonProps) {
  const [disabled, setDisabled] = React.useState(false)

  let paymentData: any = usePaymentOrderData({
    orderId: props.orderId,
    mailAddress: props.mailAddress,
  })

  const handleClick = () => {
    trackEvent({
      category: 'OrdersPage',
      action: 'Button_Pay',
      label: 'External_Handler',
    })

    setDisabled(true)
    document.getElementById('payment_' + props.orderId).submit()
  }

  if (paymentData === undefined) {
    return (
      <div className="row">
        <div className="col-md-12">
          <LinearProgress />
        </div>
      </div>
    )
  } else if (typeof paymentData === 'string') {
    return (
      <div className="row">
        <div className="col-md-12">
          <span>{paymentData}</span>
        </div>
      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col-md-12">
          <form
            action={paymentData.url}
            id={'payment_' + props.orderId}
            method="post"
          >
            {Object.entries(paymentData.params).map(([key, value]) => (
              <input type="hidden" key={key} name={key} value={value} />
            ))}
          </form>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            disabled={disabled}
          >
            <PaymentIcon /> Jetzt bezahlen
          </Button>
        </div>
      </div>
    )
  }
}
