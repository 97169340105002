import {env} from '../../../environment'

export const fetchOrderStatusData = (options: any) => {
  let url =
    env.getServiceHost() +
    'order/orderstatus?orderId=' +
    encodeURIComponent(options.orderId) +
    '&mailAddress=' +
    encodeURIComponent(options.mailAddress)

  return fetch(url).then((response) => response.json())
}
