import { useState, useEffect } from 'react'
import { fetchOrderStatusData } from './orderstatus_api'

export const useOrderStatusData = (options: any) => {
  const [orderStatusData, setOrderStatusData] = useState(undefined)

  useEffect(() => {
    fetchOrderStatusData(options).then(response => {
      setOrderStatusData(response.data)
    })
  }, [options.orderId + '-' + options.mailAddress])

  return orderStatusData
}
