import React from 'react'

import { getOrders } from '../../lib/orders_hook'
import 'moment/locale/de'
import OrderRepayButton from '../orders/OrderRepayButton'
import NpsButton from '../orders/NpsButton'
import OrderDetails from '../orders/OrderDetails'
import OrderLiveData from '../orders/OrderLiveData'

const RecentOrdersPage = () => {
  let orders = getOrders()

  if (orders.length > 0) {
    return (
      <>
        <h1>Aufträge</h1>
        {orders
          .sort((a: any, b: any) => {
            return a.orderId < b.orderId ? -1 : 1
          })
          .map((order: any) => (
            <div key={order.orderId}>
              <h2>
                Auftragsnummer <strong>{order.orderId}</strong>
              </h2>
              <div className="container">
                {order.data && (
                  <OrderDetails
                    orderId={order.orderId}
                    data={order.data}
                    date={order.date}
                  />
                )}

                {order.info && (
                  <div className="row">
                    <div className="col">{order.info}</div>
                  </div>
                )}

                <OrderLiveData
                  orderId={order.orderId}
                  mailAddress={order.mail}
                  order={order}
                />

                {!order.info && !order.paid && (
                  <OrderRepayButton
                    orderId={order.orderId}
                    mailAddress={order.mail}
                  />
                )}

                {order.paid && !order.nps && (
                  <NpsButton orderId={order.orderId} />
                )}
                <p>&nbsp;</p>
              </div>
            </div>
          ))}
      </>
    )
  } else {
    return (
      <>
        <h1>Aufträge</h1>
        <p>Noch keine Aufträge...</p>
      </>
    )
  }
}

export default RecentOrdersPage
