import React from 'react'
import { _formatThousandsWithRounding } from '../../utils/utils'
import EvilSpan from '../generic/EvilSpan'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getHash } from '../../utils/Crypto'

export interface OrderDetailsProps {
  data: any
  date: any
  orderId: any
}

export default function OrderDetails(props: OrderDetailsProps) {
  let od: any = props.data
  moment.locale('de')

  const { t } = useTranslation('translation')
  let dateString = (
    <>
      vom <strong>{moment(props.date).format('L')}</strong> (
      {moment(props.date).format('LT')} Uhr)
    </>
  )

  let price =
    od.seenPrices.materialGross + od.seenPrices.shipping - od.seenPrices.bonus

  return (
    <>
      <div className="row">
        <div className="col">
          {price > 0.01 && (
            <EvilSpan
              content={
                t('Bestellung über') +
                ' <strong>' +
                _formatThousandsWithRounding(price, 2) +
                ' ' +
                od.currency +
                '</strong> '
              }
            />
          )}
          {dateString}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ul>
            {od.cartItems.map((cartItem: any) => (
              <li
                key={
                  'order-' +
                  props.orderId +
                  '-' +
                  getHash(cartItem)
                }
              >
                {cartItem.count}x {cartItem.material.materialVariant} "
                {cartItem.item.name}"
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}
