import React from 'react'
import Layout from '../components/layout/Layout'
import RecentOrdersPage from '../components/user/RecentOrdersPage'

const OrdersAreaPage = () => {
  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-md">
            <RecentOrdersPage />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OrdersAreaPage
